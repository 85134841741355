<!-- Settings -->
<template>
  <div>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div class="m-bot-15">
          <div class="row">
            <div class="col-sm-5 col-xs-12">
              <b-form-group class="mb-3">
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"
                    v-on:keyup="fetchData(1)"
                    auto-complete="nope"
                  ></b-form-input>
                  <span class="search-icon" v-if="filter" id="clear-button">
                    <i class="fas fa-times" @click="filter = ''"></i>
                    <b-tooltip target="clear-button" variant="primary"
                      >Clear</b-tooltip
                    >
                  </span>
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter ? fetchData(1) : ''"
                      variant="primary"
                      >Search</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
            <div class="col-sm-3">
              <el-select  :value="treeLabel"  clearable placeholder="select category" ref="selectTree1" 
                    @clear="categoryClear()"
                    :disabled="this.$route.params.product_id">                      
                        <el-option :value="treeLabel" :label="treeLabel" class="tree_option">                                     
                              <el-tree                                       
                              class="filter-tree"
                              :data="category"                                       
                              :props="defaultProps"                                   
                              node-key="id"
                              :expand-on-click-node="false" 
                              @current-change="SelectCategory"
                              :default-expanded-keys ="expand"                                                                                                             
                              :highlight-current="true"  
                              @node-click="handleNodeClick"                                                                                                                                       
                              ref="tree">
                              <span class="custom-tree-node" slot-scope="{data }">
                                <span class="icon-folder">                                          
                                <i class="el-icon-folder el-fw"  aria-hidden="true"></i>
                                <span class="icon-folder_text"> {{ data.label }}</span>
                                </span>
                              </span>
                              </el-tree>
                              
                        </el-option>
                    </el-select>
              <!-- <el-select
                placeholder="Select Category"
                v-model="selectedCategory"
                @change="getCategoryvalues()"
                class="website-based-list"
                filterable
                clearable
              >
                <el-option value="" label="All Category"></el-option>
                <el-option
                  v-for="(category, index) in category_list"
                  :key="index"
                  :label="category.category_name"
                  :value="category.category_id"
                >
                </el-option>
              </el-select> -->
            </div>
            <div class="col-sm-4 col-xs-12 text-right">
              <b-button
                class="
                  rounded-3
                  btn btn-sm btn-primary
                  font-weight-bold
                  text-uppercase
                "
                variant="primary"
                @click="addProduct"
                ><i class="fa fa-plus"></i>Add Product
              </b-button>
              <b-button
                class="
                  rounded-3
                  btn btn-sm btn-warning
                  font-weight-bold
                  text-uppercase
                  ml-3
                "
                variant="primary"
                @click="ImportProduct"
                ><i
                  class="fa fa-upload"
                  style="font-size: 12px; margin-top: -5px"
                ></i>
                Import
              </b-button>
              <b-button
                class="btn-sm btn-filter rounded-3"
                v-b-toggle.collapse-1
                variant="primary"
                id="tooltip-button-variant"
                ><i class="fa fa-filter" aria-hidden="true"></i
              ></b-button>
              <b-tooltip target="tooltip-button-variant" variant="primary"
                >Column Filter</b-tooltip
              >
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-12 select-website">
            <b-collapse id="collapse-1" class="mb-3">
              <b-card>
                <p class="card-text">
                  <el-checkbox
                    :indeterminate="isIndeterminate"
                    v-model="checkAll"
                    @change="handleCheckAllChange"
                    >Select All</el-checkbox
                  >
                </p>
                <hr />
                <el-checkbox-group
                  v-model="checkedColumns"
                  @change="handleCheckedCitiesChange"
                >
                  <el-checkbox
                    v-for="(column, index) in column_list"
                    :label="column.value"
                    :key="index"
                    >{{ column.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </b-card>
            </b-collapse>
            <div class="collapse" id="collapseExample">
              <div class="card card-body"></div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table
              :data="list"
              border
              element-loading-text="Loading"
              style="width: 100%"
              v-loading="loading"
            >
             <!-- <span>{{ $index + pagination.per_page * ( pagination.current_page - 1 ) + 1 }}</span> -->
               <el-table-column type="index" width="80" label="SI">
                    <template v-slot="{ $index }">
                            <span>{{ pagination.total_rows -(( $index + pagination.per_page * ( pagination.current_page - 1 ) ))}}</span>
                    </template>
                </el-table-column>
              <!-- <el-table-column
                fixed
                align="center"
                label="Product #"
                prop="id"
                sortable
                width="120"
              >
                <template slot-scope="scope">
                  <el-tag slot="reference" size="mini"
                    ><a href="#" @click.prevent="viewProduct(scope.row.id)">{{
                      scope.row.id ? scope.row.id : ""
                    }}</a></el-tag
                  >
                </template>
              </el-table-column> -->
              <el-table-column
                label="SKU"
                prop="sku"
                sortable
                width="100"
                v-if="showAndHideColumn('sku')"
              >
                <template slot-scope="scope">
                     <el-tag slot="reference" size="mini"
                    ><a href="#" @click.prevent="viewProduct(scope.row.id)">
                      {{ scope.row.sku ? scope.row.sku : scope.row.old_sku }}</a>
                      </el-tag>
                 
                </template>
              </el-table-column>
              <el-table-column
                label="Product Name"
                prop="name"
                sortable
                :min-width="200"
                v-if="showAndHideColumn('product_name')"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.name ? scope.row.name : "" }}</span>
                </template>
              </el-table-column>
               <el-table-column
                label="Supplier Name"
                prop="main_supplier"
                sortable
                width="200"
                v-if="showAndHideColumn('supplier_name')"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.main_supplier
                      ? scope.row.main_supplier.supplier_name
                      : ""
                  }}</span>
                </template>
              </el-table-column>
               <el-table-column
                label="Supplier SKU"
                prop="supplier_sku"
                sortable
                width="120"
                v-if="showAndHideColumn('supplier_sku')"
              >
                <template slot-scope="scope">
                  
                  <span>{{
                    scope.row.main_supplier
                      ? scope.row.main_supplier.mfg_id
                      : ""
                  }}</span>
                </template>
              </el-table-column>
               <el-table-column
                label="Supplier MFG ID"
                prop="supplier_mfg_id"
                sortable
                width="130"
                v-if="showAndHideColumn('supplier_mfg_id')"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.main_supplier
                      ? scope.row.main_supplier.supplier_mfg_id
                      : ""
                  }}</span>
                </template>
              </el-table-column>
               <el-table-column
                label="Supplier UPC"
                prop="supplier_upc"
                sortable
                width="120"
                v-if="showAndHideColumn('supplier_upc')"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.main_supplier
                      ? scope.row.main_supplier.upc_code
                      : ""
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Category Name"
                prop="category_name"
                sortable
                width="150"
                v-if="showAndHideColumn('category_name')"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.product_category ? scope.row.product_category.label : ""
                  }}</span>
                </template>
              </el-table-column>
             
              <el-table-column
                label="Brand Name"
                prop="brand_name"
                sortable
                width="150"
                v-if="showAndHideColumn('brand_name')"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.brand_model ? scope.row.brand_model : ""
                  }}</span>
                </template>
              </el-table-column>
              
              <el-table-column
                label="Stock Location"
                sortable
                prop="stock_location"
                width="150"
                v-if="showAndHideColumn('stock_location')"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.stock_location ? scope.row.stock_location : ""
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Order Type"
                sortable
                prop="order_type"
                width="150"
                v-if="showAndHideColumn('order_type')"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.order_type ? scope.row.order_type : ""
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Square Feet"
                sortable
                prop="square_feet"
                width="150"
                v-if="showAndHideColumn('square_feet')"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.sq_feet ? scope.row.sq_feet : "" }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Cost Price"                
                prop="cost_price"
                width="130"
                v-if="showAndHideColumn('price')"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.cost_price ? '$'+scope.row.cost_price.toLocaleString("en-US"): "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Regular Price"                
                prop="regular_price"
                width="120"
                v-if="showAndHideColumn('regular_price')"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.regular_price ? '$'+scope.row.regular_price.toLocaleString("en-US") : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Regular %"                
                prop="regular_percentage"
                width="120"
                v-if="showAndHideColumn('regular_percentage')"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.regular_per ? scope.row.regular_per +'%' : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Sale Price"                
                prop="special_price"
                width="120"
                v-if="showAndHideColumn('sale_price')"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.special_price ? '$'+scope.row.special_price.toLocaleString("en-US") : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Contractor Price"                
                prop="contractor_price"
                width="130"
                v-if="showAndHideColumn('contractor_price')"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.contractor_price ? '$'+scope.row.contractor_price.toLocaleString("en-US") : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Dealer Price"                
                prop="dealer_amount"
                width="120"
                v-if="showAndHideColumn('dealer_amount')"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.dealer_amount ? '$'+scope.row.dealer_amount.toLocaleString("en-US") : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Wholesale Price"                
                prop="whole_amount"
                width="130"
                v-if="showAndHideColumn('whole_amount')"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.whole_amount ? '$'+scope.row.whole_amount.toLocaleString("en-US") : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="WholeSale Price"
                
                prop="wholesale_price"
                width="130"
                v-if="showAndHideColumn('wholesale_price')"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.whole_amount ? '$'+scope.row.whole_amount.toLocaleString("en-US") : "--"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Stock Quantity"                
                prop="stock_quantity"
                width="130"
                v-if="showAndHideColumn('stock_quantity')"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.stock_quantity ? scope.row.stock_quantity : "0"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="Status"                
                prop="record_status"
                width="100"
                v-if="showAndHideColumn('status')"
                align="center"
              >
                <template slot-scope="scope">
                  <el-switch
                    :active-value="1"
                    :inactive-value="0"
                    v-model="scope.row.record_status"
                    @change="
                      updateStatus(scope.row.id, scope.row.record_status)
                    "
                    style="margin: 0px"
                  ></el-switch>
                </template>
              </el-table-column>
              <el-table-column
                label="Similar Product"                
                prop="similar_product"
                width="130"
                v-if="showAndHideColumn('similar_product')"
                align="center"
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.similar_product ? scope.row.similar_product : ""
                  }}</span>
                </template>
              </el-table-column>
               <el-table-column
                label="Created By"                
                prop="created_by"
                min-width="150"
                max-width="200"
                v-if="showAndHideColumn('created_by')"
               
              >
                <template slot-scope="scope">
                  <span>{{
                    scope.row.created_by }}</span>
                </template>
              </el-table-column>
              <el-table-column
                fixed="right"
                align="center"
                label="Actions"
                width="80"
              >
                <template slot-scope="scope">
                  <el-dropdown
                    @command="handleActions($event, scope.row)"
                    size="medium"
                  >
                    <i class="flaticon2-menu-1"></i>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                      <el-dropdown-item :command="1"
                        ><i class="fa fa-eye"></i>View</el-dropdown-item
                      >
                      <el-dropdown-item :command="2"
                        ><i class="fa fa-edit"></i>Edit</el-dropdown-item
                      >
                      <el-dropdown-item :command="3"
                        ><i class="fa fa-trash"></i>Delete</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-pagination
              v-if="!loading"
              style="margin-top: 15px"
              background
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="fetchData"
              :page-count="pagination.last_page"
              :page-sizes="[50, 100, 250, 500, 1000,5000]"
              :page-size="pagination.per_page"
              :current-page.sync="pagination.current_page"
              :total="pagination.total_rows"
            >
            </el-pagination>
          </div>
        </div>
        <v-dialog />
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  getAllproducts,
  deleteProduct,
  changeStatus,   
  getCategoryTree
} from "@/api/product";
import { globalEvents } from "@/utils/globalEvents";
import UpdateInventoryQuantities from "@/components/UpdateInventoryQuantities";
import _ from 'lodash';
export default {
  name: "products",
  data() {
    return {
      list: [],
      items: [],
      expand:[],
      category:[],
      pagination: {
        total_rows: 1,
        current_page: 1,
        last_page: null,
        per_page: 50,
      },
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      selectedCategory: null,
      isShowClearIcon: false,
      filterOn: [],
      treeLabel:"",
      column_list: [
        {
          name: "SKU",
          value: "sku",
        },
        {
          name: "Product Name",
          value: "product_name",
        },
        {
          name: "Category Name",
          value: "category_name",
        },
        {
          name: "Supplier Name",
          value: "supplier_name",
        },        
         {
          name: "Supplier SKU",
          value: "supplier_sku",
        },  
         {
          name: "Supplier MFG Id",
          value: "supplier_mfg_id",
        },      
         {
          name: "Supplier UPC",
          value: "supplier_upc",
        },
        
        {
          name: "Square Feet",
          value: "square_feet",
        },
        {
          name: "Cost Price",
          value: "price",
        },
        {
          name: "Regular %",
          value: "regular_percentage",
        },
        {
          name: "Regular Price",
          value: "regular_price",
        },
        {
          name: "Sale Price",
          value: "sale_price",
        },
        {
          name: "Contractor Price",
          value: "contractor_price",
        },

        {
          name: "Dealer Price",
          value: "dealer_amount",
        },

        {
          name: "Wholesale Price",
          value: "whole_amount",
        },
        {
          name: "Stock Quantity",
          value: "stock_quantity",
        },
        {
          name: "Brand Name",
          value: "brand_name",
        },
        
        {
          name: "Stock Location",
          value: "stock_location",
        },
        {
          name: "Order Type",
          value: "order_type",
        },
        {
          name: "Status",
          value: "status",
        },
        {
          name: "Similar Product",
          value: "similar_product",
        },
         {
          name: "Created By",
          value: "created_by",
        },
        
      ],
      checkAll: false,
      isIndeterminate: true,
      category_list: [],
      checkedColumns: [
        "sku",
        "product_name",
        "category_name",
        "supplier_name",
        "price",
        "regular_price",
        "regular_percentage",
        "stock_quantity",
        "contractor_price",
        "status",
        "sale_price",
        "dealer_amount",
        "wholesale_price",
        "supplier_sku",
        "supplier_mfg_id",
        "product_image"
      ],
       defaultProps: {
        children: "children",
        label: "label",
      },
      image_url :process.env.VUE_APP_BASE_IMAGE_URL
    };
  },
  watch: {
   filter : _.debounce(function(n){
     this.loading = true;
      if (!n) {
        this.fetchData(1);
      }
    }, 200)
  },
  computed: {},

  components: {},

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Product List" },
    ]);
  },
  created() {
    this.fetchData(1);
    this.getCategory();
    globalEvents.$on("InventoryUpdated", (added) => {
      if (added) {
        this.fetchData(1);
        this.$showResponse(
          "success",
          "Inventory details updated successfully."
        );
      }
    });
  },
  methods: {
    clearShow() {
      this.isShowClearIcon = false;
      if (this.filter) {
        this.isShowClearIcon = true;
      }
    },
  SelectCategory(currentObj) {
      this.selectedCategory = currentObj.id;
     // this.form.category_name = currentObj.label; 
      this.fetchData(1);
    },
    handleNodeClick (node) {     
      this.treeLabel = node.label
      this.$refs.selectTree1.blur() 
    },
    ImportProduct() {
      this.$router.push({ path: "/product/imports" });
    },
    showAndHideColumn(column_name) {
      return this.$isColumnShow(column_name, this.checkedColumns);
    },
    updateStatus(product_id, status) {
      changeStatus(product_id, status).then((response) => {
        this.$showResponse("success", response.data.message);
      });
    },
    handleSizeChange(per_page) {
      this.pagination.per_page = per_page;
      this.fetchData(1);
    },

    fetchData(p) {
      this.loading = true;
      let params = { page: p };
      if (this.filter) {
        params.filter = this.filter;
      }
      if (this.sort) {
        params.sort = this.sort;
      }
      if (this.pagination.per_page) {
        params.per_page = this.pagination.per_page;
      }
      if (this.selectedCategory) {
        params.selected_category = this.selectedCategory;
      }
      getAllproducts(params).then((response) => {
        this.list = [];
        this.list = response.data.data.data;       
        this.pagination.current_page = response.data.data.current_page;
        this.pagination.last_page = response.data.data.last_page;
        this.pagination.total_rows = response.data.data.total;
        this.loading = false;
        this.$set(this.list, "_showDetails", true);
      });
      
    },
     categoryClear(){     
      this.treeLabel = '';
      this.selectedCategory = '';      
      this.expand = [];
      this.fetchData(1);
    },
    getCategory() {
      this.loading = true;
      getCategoryTree().then((response) => {       
        this.category = response.data.data;
        this.loading = false;
      });
    },
    getCategoryvalues() {
      this.fetchData(1);
    },
    addProduct() {
      this.$router.push({ path: "/product/create" });
    },
    handleCheckAllChange(val) {
      this.checkedColumns = [];
      if (val) {
        this.column_list.map((column) => {
          this.checkedColumns.push(column.value);
        });
      }
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.column_list.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.column_list.length;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    viewProduct(product_id) {
      this.$router.push({ path: "/product/view/" + product_id });
    },
    handleActions(evt, row) {
      if (evt === 1) {
        this.$router.push({ path: "/product/view/" + row.id });
      } else if (evt === 2) {
        this.$router.push({ path: "/product/edit/" + row.id });
      } else if (evt === 3) {
        this.$bvModal
          .msgBoxConfirm("Are you sure you want to delete ?", {
            title: "Please Confirm",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "YES",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.loading = true;
              deleteProduct(row.id).then((response) => {
                if (response.data.status) {
                  this.fetchData(1);
                  this.$showResponse("success", response.data.message);
                }
                this.loading = false;
              });
            } else {
              return false;
            }
          });
      } else if (evt === 4) {
        this.$modal.show(
          UpdateInventoryQuantities,
          {
            inventory: row,
          },
          {
            draggable: true,
            transition: "pop-out",
            height: "auto",
            clickToClose: true,
            adaptive: true,
            width: "450px",
          }
        );
      }
    },
  },
};
</script>
<style scoped>
.add_upc {
  position: absolute;
}
.el-scrollbar__view .el-select-dropdown__list{
  padding-bottom: 0px !important;
}
.tree_option {
    overflow-y: scroll;
    height: auto;
    padding: 10px 10px;
}

.add_option{
    padding: 0px;
    overflow-y: unset;
}
.min-input{  
  width: 60%;
}
</style>